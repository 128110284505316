// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-container {
  border-radius: 8px;
  padding: 16px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--widget-grey-light);
  border: 1px solid var(--widget-grey-light);
  transition: 0.2s all;
}

.input-error {
  background-color: var(--widget-light-red);
  border: 1px solid var(--widget-red);
}

.input {
  font-size: 16px;
  width: 100%;
  border: none;
  outline: none;
  background: none;
  font-family: Inter;
}

.caption {
  font-size: 14px;
  line-height: normal;
  color: var(--widget-grey);
  padding-bottom: 24px;
  border-bottom: 1px solid var(--widget-stroke);
}

.card-details-container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  p {
    margin: 6px;
  }
}

.card-details {
  display: flex;
  flex-direction: column;
  gap: 6px;
  flex: 1;
}

.card-details-row {
  display: flex;
  gap: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/card-details/styles.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,0CAA0C;EAC1C,0CAA0C;EAC1C,oBAAoB;AACtB;;AAEA;EACE,yCAAyC;EACzC,mCAAmC;AACrC;;AAEA;EACE,eAAe;EACf,WAAW;EACX,YAAY;EACZ,aAAa;EACb,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,yBAAyB;EACzB,oBAAoB;EACpB,6CAA6C;AAC/C;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;;EAET;IACE,WAAW;EACb;AACF;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;EACR,OAAO;AACT;;AAEA;EACE,aAAa;EACb,SAAS;AACX","sourcesContent":[".input-container {\n  border-radius: 8px;\n  padding: 16px 12px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  background-color: var(--widget-grey-light);\n  border: 1px solid var(--widget-grey-light);\n  transition: 0.2s all;\n}\n\n.input-error {\n  background-color: var(--widget-light-red);\n  border: 1px solid var(--widget-red);\n}\n\n.input {\n  font-size: 16px;\n  width: 100%;\n  border: none;\n  outline: none;\n  background: none;\n  font-family: Inter;\n}\n\n.caption {\n  font-size: 14px;\n  line-height: normal;\n  color: var(--widget-grey);\n  padding-bottom: 24px;\n  border-bottom: 1px solid var(--widget-stroke);\n}\n\n.card-details-container {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n\n  p {\n    margin: 6px;\n  }\n}\n\n.card-details {\n  display: flex;\n  flex-direction: column;\n  gap: 6px;\n  flex: 1;\n}\n\n.card-details-row {\n  display: flex;\n  gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
