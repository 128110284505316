// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-container {
  display: flex;
  flex: 1;
  align-items: center;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;

  span.status {
    font-weight: 500;
    font-size: 20px;
    font-weight: 500;
    color: var(--widget-text-color);
    text-align: center;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/payment-status-waiting/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,OAAO;EACP,mBAAmB;EACnB,WAAW;EACX,sBAAsB;EACtB,8BAA8B;EAC9B,SAAS;;EAET;IACE,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,+BAA+B;IAC/B,kBAAkB;EACpB;AACF","sourcesContent":[".loader-container {\n  display: flex;\n  flex: 1;\n  align-items: center;\n  width: 100%;\n  flex-direction: column;\n  justify-content: space-between;\n  gap: 40px;\n\n  span.status {\n    font-weight: 500;\n    font-size: 20px;\n    font-weight: 500;\n    color: var(--widget-text-color);\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
