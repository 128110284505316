// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-payment-container {
  width: 100%;

  display: flex;
  flex-direction: column;
  padding: 32px;
  gap: 24px;
}

.select-payment-title {
  font-size: 22px;
  font-weight: 500;
  margin: 0;
}

.cards-container {
  display: flex;
  gap: 16px;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 260px;
  height: 140px;
  padding: 20px 12px;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid var(--widget-stroke);
  transition: all 0.2s;
}

.card:hover {
  border: 1px solid var(--widget-blue);
  background: var(--widget-light-blue);
}

.logo-container {
  display: flex;
  gap: 14px;
}
`, "",{"version":3,"sources":["webpack://./src/components/select-payment/styles.css"],"names":[],"mappings":"AAAA;EACE,WAAW;;EAEX,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,SAAS;AACX;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;EACf,sCAAsC;EACtC,oBAAoB;AACtB;;AAEA;EACE,oCAAoC;EACpC,oCAAoC;AACtC;;AAEA;EACE,aAAa;EACb,SAAS;AACX","sourcesContent":[".select-payment-container {\n  width: 100%;\n\n  display: flex;\n  flex-direction: column;\n  padding: 32px;\n  gap: 24px;\n}\n\n.select-payment-title {\n  font-size: 22px;\n  font-weight: 500;\n  margin: 0;\n}\n\n.cards-container {\n  display: flex;\n  gap: 16px;\n}\n\n.card {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  width: 260px;\n  height: 140px;\n  padding: 20px 12px;\n  border-radius: 8px;\n  cursor: pointer;\n  border: 1px solid var(--widget-stroke);\n  transition: all 0.2s;\n}\n\n.card:hover {\n  border: 1px solid var(--widget-blue);\n  background: var(--widget-light-blue);\n}\n\n.logo-container {\n  display: flex;\n  gap: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
