// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stepper-container {
  width: 100%;

  display: flex;
  align-items: center;
  gap: 4px;

  padding: 32px 32px 20px;

  border-bottom: 1px solid var(--widget-stroke);
  color: var(--widget-blue);
}

.step-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--widget-stroke);
  min-width: 36px;
  min-height: 36px;
  border-radius: 50%;
  color: var(--widget-stroke);
}

.step-divider {
  border-bottom: 1px solid var(--widget-stroke);
  width: 100%;
  border-radius: 50%;
}

.active {
  border-color: var(--widget-blue);
  color: var(--widget-blue);
}
`, "",{"version":3,"sources":["webpack://./src/components/stepper/styles.css"],"names":[],"mappings":"AAAA;EACE,WAAW;;EAEX,aAAa;EACb,mBAAmB;EACnB,QAAQ;;EAER,uBAAuB;;EAEvB,6CAA6C;EAC7C,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sCAAsC;EACtC,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,2BAA2B;AAC7B;;AAEA;EACE,6CAA6C;EAC7C,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,gCAAgC;EAChC,yBAAyB;AAC3B","sourcesContent":[".stepper-container {\n  width: 100%;\n\n  display: flex;\n  align-items: center;\n  gap: 4px;\n\n  padding: 32px 32px 20px;\n\n  border-bottom: 1px solid var(--widget-stroke);\n  color: var(--widget-blue);\n}\n\n.step-circle {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border: 1px solid var(--widget-stroke);\n  min-width: 36px;\n  min-height: 36px;\n  border-radius: 50%;\n  color: var(--widget-stroke);\n}\n\n.step-divider {\n  border-bottom: 1px solid var(--widget-stroke);\n  width: 100%;\n  border-radius: 50%;\n}\n\n.active {\n  border-color: var(--widget-blue);\n  color: var(--widget-blue);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
