"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("preact/jsx-runtime");
var compat_1 = require("preact/compat");
var NovaBanka = function (_a) {
    var onClose = _a.onClose, onBack = _a.onBack, onNext = _a.onNext, paymentRequest = _a.paymentRequest, selectedCrypto = _a.selectedCrypto;
    var ref = (0, compat_1.useRef)(null);
    return ((0, jsx_runtime_1.jsx)("div", { className: "step-container", style: { padding: 0 }, children: (0, jsx_runtime_1.jsx)("iframe", { ref: ref, onLoad: function () {
                var _a, _b;
                try {
                    ((_b = (_a = ref.current) === null || _a === void 0 ? void 0 : _a.contentWindow) === null || _b === void 0 ? void 0 : _b.document) && onNext();
                }
                catch (e) {
                    console.log("Cross domain navigation");
                }
            }, style: { width: "100%", height: "850px" }, src: paymentRequest === null || paymentRequest === void 0 ? void 0 : paymentRequest.externalPaymentUrl }) }));
};
exports.default = (0, compat_1.memo)(NovaBanka);
