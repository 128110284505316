// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.widget-button {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  outline: none;
  cursor: pointer;
  height: 50px;
  font-size: 16px;
  transition: all 0.2s;
}
.widget-button:disabled {
  pointer-events: none;
  background: var(--widget-grey);
  color: var(--widget-button-text-color);
  border: 1px solid var(--widget-grey);
}

.primary-button {
  background: var(--widget-blue);
  color: var(--widget-button-text-color);
  border: 1px solid var(--widget-blue);
}

.primary-button:hover {
  background: var(--widget-dark-blue);
  border: 1px solid var(--widget-dark-blue);
}

.secondary-button {
  border: 1px solid var(--widget-stroke);
  color: var(--widget-text-color);
  background: transparent;
}

.secondary-button:hover {
  border: 1px solid var(--widget-blue);
}

.danger-button {
  border: 1px solid var(--widget-red);
  color: var(--widget-button-text-color);
  background: var(--widget-red);
}

.danger-button:hover {
  border: 1px solid var(--widget-light-red);
}
`, "",{"version":3,"sources":["webpack://./src/components/button/styles.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,kBAAkB;EAClB,aAAa;EACb,eAAe;EACf,YAAY;EACZ,eAAe;EACf,oBAAoB;AACtB;AACA;EACE,oBAAoB;EACpB,8BAA8B;EAC9B,sCAAsC;EACtC,oCAAoC;AACtC;;AAEA;EACE,8BAA8B;EAC9B,sCAAsC;EACtC,oCAAoC;AACtC;;AAEA;EACE,mCAAmC;EACnC,yCAAyC;AAC3C;;AAEA;EACE,sCAAsC;EACtC,+BAA+B;EAC/B,uBAAuB;AACzB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,mCAAmC;EACnC,sCAAsC;EACtC,6BAA6B;AAC/B;;AAEA;EACE,yCAAyC;AAC3C","sourcesContent":[".widget-button {\n  width: 100%;\n  padding: 10px;\n  border-radius: 8px;\n  outline: none;\n  cursor: pointer;\n  height: 50px;\n  font-size: 16px;\n  transition: all 0.2s;\n}\n.widget-button:disabled {\n  pointer-events: none;\n  background: var(--widget-grey);\n  color: var(--widget-button-text-color);\n  border: 1px solid var(--widget-grey);\n}\n\n.primary-button {\n  background: var(--widget-blue);\n  color: var(--widget-button-text-color);\n  border: 1px solid var(--widget-blue);\n}\n\n.primary-button:hover {\n  background: var(--widget-dark-blue);\n  border: 1px solid var(--widget-dark-blue);\n}\n\n.secondary-button {\n  border: 1px solid var(--widget-stroke);\n  color: var(--widget-text-color);\n  background: transparent;\n}\n\n.secondary-button:hover {\n  border: 1px solid var(--widget-blue);\n}\n\n.danger-button {\n  border: 1px solid var(--widget-red);\n  color: var(--widget-button-text-color);\n  background: var(--widget-red);\n}\n\n.danger-button:hover {\n  border: 1px solid var(--widget-light-red);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
