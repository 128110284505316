"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCountdown = void 0;
var hooks_1 = require("preact/hooks");
var useCountdown = function (onExpire, date) {
    var _a = (0, hooks_1.useState)(function () {
        if (!date)
            return 0;
        var expirationDate = new Date(date);
        var now = new Date();
        var differenceInMs = expirationDate.getTime() - now.getTime();
        return Math.floor(differenceInMs / 1000);
    }), timeLeft = _a[0], setTimeLeft = _a[1];
    var intervalRef = (0, hooks_1.useRef)(null);
    (0, hooks_1.useEffect)(function () {
        if (timeLeft > 0) {
            intervalRef.current = window.setInterval(function () {
                setTimeLeft(function (prevTime) {
                    if (prevTime <= 1) {
                        clearInterval(intervalRef.current);
                        onExpire === null || onExpire === void 0 ? void 0 : onExpire();
                        return 0;
                    }
                    return prevTime - 1;
                });
            }, 1000);
        }
        return function () { return clearInterval(intervalRef.current); };
    }, [timeLeft]);
    return timeLeft;
};
exports.useCountdown = useCountdown;
