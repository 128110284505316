"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatNumber = formatNumber;
function formatNumber(value, decimals) {
    if (decimals === void 0) { decimals = 2; }
    if (isNaN(Number(value)) || value === undefined) {
        return "0";
    }
    var numValue = typeof value === "number" ? value : parseFloat(value);
    var withDecimals = numValue.toFixed(decimals);
    var _a = withDecimals.split("."), whole = _a[0], decimal = _a[1];
    var wholeWithSeparator = parseInt(whole, 10).toLocaleString("en-US");
    var result;
    if (decimal === undefined) {
        result = wholeWithSeparator;
    }
    else {
        result = "".concat(wholeWithSeparator, ".").concat(decimal);
    }
    if (parseFloat(result) === 0)
        result = "0";
    return result;
}
