"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppContainer = exports.GlobalStyle = void 0;
var styled_components_1 = __importStar(require("styled-components"));
exports.GlobalStyle = (0, styled_components_1.createGlobalStyle)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  :root {\n    --widget-background-color: #ffffff;\n    --widget-text-color: #000000;\n    --widget-blue: #277cfb;\n    --widget-dark-blue: #1b56af;\n    --widget-light-blue: #f2f7ff;\n    --widget-red: #f94a4a;\n    --widget-light-red: #ffe8e7;\n    --widget-stroke: #dce4f1;\n    --widget-grey: #8c95a5;\n    --widget-grey-light: #f9fafb;\n    --widget-button-text-color: #ffffff;\n  }\n\n  [data-theme=\"dark\"] {\n    --widget-background-color: #202224;\n    --widget-text-color: #ffffff;\n    --widget-blue: #277cfb;\n    --widget-dark-blue: #1b56af;\n    --widget-light-blue: #f2f7ff;\n    --widget-red: #f94a4a;\n    --widget-light-red: #4a2f2d;\n    --widget-stroke: #33373e;\n    --widget-grey: #5f6875;\n    --widget-grey-light: #282c30;\n    --widget-button-text-color: #ffffff;\n  }\n"], ["\n  :root {\n    --widget-background-color: #ffffff;\n    --widget-text-color: #000000;\n    --widget-blue: #277cfb;\n    --widget-dark-blue: #1b56af;\n    --widget-light-blue: #f2f7ff;\n    --widget-red: #f94a4a;\n    --widget-light-red: #ffe8e7;\n    --widget-stroke: #dce4f1;\n    --widget-grey: #8c95a5;\n    --widget-grey-light: #f9fafb;\n    --widget-button-text-color: #ffffff;\n  }\n\n  [data-theme=\"dark\"] {\n    --widget-background-color: #202224;\n    --widget-text-color: #ffffff;\n    --widget-blue: #277cfb;\n    --widget-dark-blue: #1b56af;\n    --widget-light-blue: #f2f7ff;\n    --widget-red: #f94a4a;\n    --widget-light-red: #4a2f2d;\n    --widget-stroke: #33373e;\n    --widget-grey: #5f6875;\n    --widget-grey-light: #282c30;\n    --widget-button-text-color: #ffffff;\n  }\n"])));
exports.AppContainer = styled_components_1.default.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  left: 0;\n  top: 0;\n  font-family: ", ";\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1400;\n  background-color: rgba(0, 0, 0, 0.5);\n  & {\n    * {\n      box-sizing: border-box;\n    }\n  }\n"], ["\n  position: absolute;\n  left: 0;\n  top: 0;\n  font-family: ", ";\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1400;\n  background-color: rgba(0, 0, 0, 0.5);\n  & {\n    * {\n      box-sizing: border-box;\n    }\n  }\n"])), function (p) { return p.fontFamily || "sans-serif"; });
var templateObject_1, templateObject_2;
