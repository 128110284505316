// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment-status-container {
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  gap: 32px;
}

.status-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 40px;

  span.status {
    font-weight: 500;
    font-size: 20px;
    font-weight: 500;
    color: var(--widget-text-color);
  }
}

@media (max-width: 768px) {
  .status-container {
    gap: 20px;

    span.status {
      font-size: 18px;
    }
  }
}

.progress {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 235px;
}
`, "",{"version":3,"sources":["webpack://./src/components/payment-status/styles.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;EACb,SAAS;AACX;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,8BAA8B;EAC9B,SAAS;;EAET;IACE,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,+BAA+B;EACjC;AACF;;AAEA;EACE;IACE,SAAS;;IAET;MACE,eAAe;IACjB;EACF;AACF;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;AACf","sourcesContent":[".payment-status-container {\n  width: 600px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 32px;\n  gap: 32px;\n}\n\n.status-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-between;\n  gap: 40px;\n\n  span.status {\n    font-weight: 500;\n    font-size: 20px;\n    font-weight: 500;\n    color: var(--widget-text-color);\n  }\n}\n\n@media (max-width: 768px) {\n  .status-container {\n    gap: 20px;\n\n    span.status {\n      font-size: 18px;\n    }\n  }\n}\n\n.progress {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 235px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
