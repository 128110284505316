"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.STATUSES = exports.PaymentMethods = exports.PaymentVariants = void 0;
var PaymentVariants;
(function (PaymentVariants) {
    PaymentVariants["Deposit"] = "Deposit";
    PaymentVariants["Buy"] = "Buy";
})(PaymentVariants || (exports.PaymentVariants = PaymentVariants = {}));
var PaymentMethods;
(function (PaymentMethods) {
    PaymentMethods["Card"] = "Card";
    PaymentMethods["Crypto"] = "Crypto";
})(PaymentMethods || (exports.PaymentMethods = PaymentMethods = {}));
var STATUSES;
(function (STATUSES) {
    STATUSES["ChoosePaymentMethod"] = "CHOOSE_PAYMENT_METHOD";
    STATUSES["WaitingForAddress"] = "WAITING_FOR_ADDRESS";
    STATUSES["WaitingForPayment"] = "WAITING_FOR_PAYMENT";
    STATUSES["PartiallyPaid"] = "PARTIALLY_PAID";
    STATUSES["Paid"] = "PAID";
    STATUSES["Expired"] = "EXPIRED";
    STATUSES["Canceled"] = "CANCELED";
})(STATUSES || (exports.STATUSES = STATUSES = {}));
